import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class IndexPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[
            `geekery`,
            `portland`,
            `stockholm`,
            `blog`,
            `role-playing`,
            `rpg`,
            `games`,
            `coriolis`,
            `rpgaday`,
            `drakar och demoner`,
            `ereb altor`,
            `fria ligan`,
            `free league publishing`,
          ]}
        />
        <p>
          Not much to see here yet. There are a few{" "}
          <Link to="/blog/">blog entries</Link>, though.
        </p>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
